import React from 'react';
import './Transition.css';

const Transition = ({ text }) => {
     
    return (
        <div class="transition-background">
           {text}
        </div>
    );
}
   
export default Transition;