import React from 'react';
import './Segment4.css';

const Segment4 = () => {
    return (
        <div className="row segment4-background">
            <div className="col-lg-6 segment4-text-container">
                <div className="segment4-text">
                    Tortured twice by his own king and jealous superiors, the Nanjung Ilgi conveys Yi’s daily resilience and lifelong dedication to his people.
                </div>
            </div>
        </div>
    )
}

export default Segment4;