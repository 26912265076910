import React from 'react';
import './Segment5.css';

const Segment5 = () => {
    return (
        <div className="segment5-background">
            <div className="segment5-text-container">
                <div className="segment5-title-text">
                    The Legacy of the Nanjung Ilgi
                </div>
                    From the harsh camp life and decaying state of Korean morale to Yi’s innovative Turtle Ship, Crane Wing formation, and relentless counterattacks, experience the Imjin War one day at a time.
                    <br></br><br></br>
                    "He who seeks his death shall live, and he who seeks his life shall die."
            </div>
        </div>
    )
}

export default Segment5;