import React from 'react';
import './Segment2.css';

const Segment2 = () => {
    return (
        <div className="row segment2-background">
            <div className="col-lg-6 mobile-remove">

            </div>
            <div className="col-lg-6 segment2-text-container">
                <div className="segment2-text">
                    The Nanjung Ilgi is a historic diary that details one of the world’s most successful admirals in his struggle to save his homeland.
                </div>
            </div>
        </div>
    )
}

export default Segment2;