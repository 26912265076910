import React from 'react';
import './Segment3.css';

const Segment3 = () => {
    return (
        <div className="row segment3-background">
            <div className="segment3-text-container">
                <div className="segment3-text">
                    <p>
                        23 wins - 0 losses
                        <br></br><br></br>
                        Enemy ships destroyed: 400+
                        Allied ships lost: 0
                        <br></br><br></br>
                        Outnumbered 13 to 133, Admiral Yi prevailed
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Segment3;