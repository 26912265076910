import React from 'react';
import './App.css';
import LandingPage from './Segments/LandingPage.js';
import Transition from './Segments/Transition.js';
import Segment2 from './Segments/Segment2.js';
import Segment3 from './Segments/Segment3.js';
import Segment4 from './Segments/Segment4.js';
import Segment5 from './Segments/Segment5.js';
import Final from './Segments/Final.js';

function App() {
    return (
        <div className="parent">
            <div className="arrow bounce">
                <ion-icon name="caret-down-outline" className="indicator"></ion-icon>
            </div>
            <LandingPage></LandingPage>
            <Transition text={"Korea's greatest military leader"}></Transition>
            <Segment2></Segment2>
            <Transition text={"His tactical brilliance was unmatched"}></Transition>
            <Segment3></Segment3>
            <Transition text={"A man of unbreakable loyalty"}></Transition>
            <Segment4></Segment4>
            <Transition text={"The first war diary of its kind"}></Transition>
            <Segment5></Segment5>
            <Transition text={"Savior of the Joseon Dynasty"}></Transition>
            <Final></Final>
        </div>
    );
}

export default App;