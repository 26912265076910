import React from 'react';
import './Final.css';
import Button from 'react-bootstrap/Button';

const Final = () => {
    return (
        <div className="row final-background">
            <div className="col-lg-6 final-left-container">
                <div className="book-cover">

                </div>
            </div>
            <div className="col-lg-6 final-right-container">
                For the first time, the Nanjung Ilgi is available in English at an affordable price.
                <div className="top-spacer">
                    Own a piece of history:
                </div>
                <Button className="purchase-button" onClick={(e) => {
                    window.open("https://www.etsy.com/listing/889428876/nanjung-ilgi?ga_order=most_relevant&ga_search_type=all&ga_view_type=gallery&ga_search_query=&ref=sr_gallery-1-4", "_blank");
                }}>
                    Buy Now    
                </Button>
            </div>
        </div>
    )
}

export default Final;