import React from 'react';
import './LandingPage.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';

const LandingPage = () => {
    return (
        <div className="row landingpage-background">
            <div className="col-lg-6 title-container">
                <div className="title">
                    Nanjung Ilgi
                </div>
                <div className="subtitle">
                    The War Diary of Admiral Yi Sun-Shin
                </div>
                <Button className="hello" onClick={(e) => {
                    window.open("https://www.etsy.com/listing/889428876/nanjung-ilgi?ga_order=most_relevant&ga_search_type=all&ga_view_type=gallery&ga_search_query=&ref=sr_gallery-1-4", "_blank");
                }}>
                    Buy Now    
                </Button>
            </div>
        </div>
    );
}
   
export default LandingPage;